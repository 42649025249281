import React, { useState } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { AdminSearchEdit } from "./components/AdminSearch/AdminSearchEdit/AdminSearchEdit";
import { MainPage } from "./components/mainpage/MainPage";
import { Logs } from "./components/Logs/Logs";
import { ViewLog } from "./components/Logs/Viewlog";
import ViewDocument from "./components/AdminSearch/ViewDocument/ViewDocument";
import EditDocument from "./components/AdminSearch/EditDocument/EditDocument";
// import { RtoE } from "./components/rtoE/RtoE";
// import { RtoN } from "./components/RtoN/RtoN";
// import { RtoNtoDbtoEs } from "./components/RtoNtoDBtoES/RtoNtoDbtoEs";
// import { RtoNtoDb } from "./components/RtoNtoDb/RtoNtoDb";
const SecurityPopup = ({ onAccessGranted }) => {
  const [open, setOpen] = useState(true); // Modal starts as open
  const [password, setPassword] = useState('');
  const correctPassword = "Sifreli_1919!!"; // Set your security password here

  const handleClose = () => {
    if (password === correctPassword) {
      setOpen(false); // Close the modal if the password is correct
      onAccessGranted(); // Notify parent component that access is granted
    } else {
      alert('Incorrect password!');
    }
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Security Check
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please enter the password to access this page:
        </Typography>
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        <Button variant="contained" onClick={handleClose}>
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAccessGranted = () => {
    setIsAuthenticated(true); // Allow access to the main content
  };
  return (
    <>
    {!isAuthenticated && <SecurityPopup onAccessGranted={handleAccessGranted} />}
      {isAuthenticated && (
        <Router>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/adminsearch/edit/:systemgeneratedfilename" element={<AdminSearchEdit />} />
            <Route path="/adminsearch/edit/view/:systemgeneratedfilename/:pagenumber/:FileId" element={<ViewDocument />} />
            <Route path="/adminsearch/edit/edit/:systemgeneratedfilename/:pagenumber/:FileId" element={<EditDocument />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/log/:logid" element={<ViewLog />} />
          </Routes>
        </Router>
      )}
    </>
    
  );
}

export default App;
