// src/components/DataTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  Button, CircularProgress, TextField, Paper, Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // To navigate to the view page
import "./logs.scss";
import { API_URL } from '../../environment';

export const Logs = () => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortColumn, setSortColumn] = useState('datetime');
  const [sortDirection, setSortDirection] = useState('asc');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    ipaddress: '',
    fileid: '',
    pageno: '',
    message: '',
    systemgeneratedfilename:'',
    originalfilename:'',
    old_content: '',
    new_content: '',
    datetime: '', // Date only (e.g., 'YYYY-MM-DD')
  });
  const navigate = useNavigate(); // Hook to navigate

  useEffect(() => {
    fetchData();
  }, [currentPage, sortColumn, sortDirection, filters]);

  const fetchData = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${API_URL}/api/logsmodified`, {
        params: {
          pageno: currentPage + 1,
          rows_per_page: 10,
          sort_column: sortColumn,
          sort_direction: sortDirection,
          ...filters,
        },
      });
      console.log("respo: ", response.data.actions[0])
      setData(response.data.actions[0]);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch logs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSort = (column) => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(column);
  };

  const handleView = (row) => {
    navigate(`/log/${row.logid}`, { state: row });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    // If the datetime filter is being changed, convert the value to a date string format (YYYY-MM-DD)
    if (name === 'datetime') {
      // Ensure the value is formatted correctly for the API
      const dateValue = value ? new Date(value).toISOString().split('T')[0] : '';
      setFilters((prevFilters) => ({ ...prevFilters, [name]: dateValue }));
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    }
  };

  return (
    <div className="table-container">
      <h2>Action Logs</h2>
      {error && <Alert severity="error">{error}</Alert>}
      <Paper sx={{ padding: 2, marginBottom: 2 }}>
        <div className="filters">
          <TextField
            label="IP Address"
            name="ipaddress"
            value={filters.ipaddress}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:150,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label="File ID"
            name="fileid"
            value={filters.fileid}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:100,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label="Page No."
            name="pageno"
            value={filters.pageno}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:120,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label="Message"
            name="message"
            value={filters.message}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:200,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label="SG file name"
            name="systemgeneratedfilename"
            value={filters.systemgeneratedfilename}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:200,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label="Original File file name"
            name="originalfilename"
            value={filters.originalfilename}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:200,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label="Old Content"
            name="old_content"
            value={filters.old_content}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:200,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label="New Content"
            name="new_content"
            value={filters.new_content}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:200,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
          <TextField
            label=""
            name="datetime"
            type="date" // Use type="date" for a date input
            value={filters.datetime}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            style={{
              width:200,
              marginRight:2,
              fontSize:12
            }}
            margin="normal"
          />
        </div>
      </Paper>
      {loading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort('logid')} style={{ cursor: 'pointer' }}>Log ID</TableCell>
                <TableCell onClick={() => handleSort('ipaddress')} style={{ cursor: 'pointer' }}>IP Address</TableCell>
                <TableCell onClick={() => handleSort('userid')} style={{ cursor: 'pointer', width:70 }}>UID</TableCell>
                <TableCell onClick={() => handleSort('fileid')} style={{ cursor: 'pointer', width:100 }}>File ID</TableCell>
                <TableCell onClick={() => handleSort('pageno')} style={{ cursor: 'pointer', width:100 }}>Page No.</TableCell>
                <TableCell onClick={() => handleSort('message')} style={{ cursor: 'pointer', width:250 }}>Message</TableCell>
                <TableCell onClick={() => handleSort('systemgeneratedfilename')} style={{ cursor: 'pointer' }}>SG Filename</TableCell>
                <TableCell onClick={() => handleSort('originalfilename')} style={{ cursor: 'pointer' }}>OG Filename</TableCell>
                <TableCell onClick={() => handleSort('old_content')} style={{ cursor: 'pointer' }}>Old Content</TableCell>
                <TableCell onClick={() => handleSort('new_content')} style={{ cursor: 'pointer' }}>New Content</TableCell>
                <TableCell onClick={() => handleSort('datetime')} style={{ cursor: 'pointer' }}>Date Time</TableCell>
                <TableCell>Actions</TableCell> {/* Column for the View button */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row) => (
                  <TableRow key={row.logid}>
                    <TableCell>{row.logid}</TableCell>
                    <TableCell>{row.ipaddress}</TableCell>
                    <TableCell>{row.userid}</TableCell>
                    <TableCell>{row.fileid}</TableCell>
                    <TableCell>{row.pageno}</TableCell>
                    <TableCell>{row.message}</TableCell>
                    <TableCell>{row.systemgeneratedfilename}</TableCell>
                    <TableCell>{row.originalfilename}</TableCell>
                    <TableCell>{row.old_content}</TableCell>
                    <TableCell>{row.new_content}</TableCell>
                    <TableCell>{row.datetime}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleView(row)}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} className="text-center">
                    No logs available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalRecords}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={10}
            rowsPerPageOptions={[10]}
          />
        </TableContainer>
      )}
    </div>
  );
};
