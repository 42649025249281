import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination, Typography, Container, Grid, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { API_URL } from '../../environment';
import { useNavigate } from 'react-router-dom';

export const AdminSearchFilter = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [orderBy, setOrderBy] = useState('year');
  const [orderDirection, setOrderDirection] = useState('asc');
  
  // Search fields
  const [fileId, setFileId] = useState('');
  const [systemGeneratedFileName, setSystemGeneratedFileName] = useState('');
  const [originalFileName, setOriginalFileName] = useState('');
  const [filePath, setFilePath] = useState('');
  const [fileType, setFileType] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [author, setAuthor] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [notes, setNotes] = useState('');
  const [userId, setUserId] = useState('');
  const [message, setMessage] = useState('');
  const [startPages, setStartPages] = useState('');
  const [endPages, setEndPages] = useState('');
  const [textSize, setTextSize] = useState('');
  const [pdfSize, setPdfSize] = useState('');
  const [virusStatus, setVirusStatus] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [nextStep, setNextStep] = useState('');
  const [fromUploadTime, setFromUploadTime] = useState('');
  const [toUploadTime, setToUploadTime] = useState('');
  const [reviewStatus, setReviewStatus] = useState('');
  const [results, setResults] = useState([]);
  
  const navigate = useNavigate();
  
  useEffect(() => {
    // Sort results whenever `results` or sorting options change
    const sorted = [...results].sort((a, b) => {
      const aValue = a[orderBy]?.toString().toLowerCase();
      const bValue = b[orderBy]?.toString().toLowerCase();
      if (orderDirection === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });
    setSortedData(sorted);
  }, [results, orderBy, orderDirection]);

  const handleSort = (field) => {
    const isAsc = orderBy === field && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
    setPage(0);

    const sorted = [...results].sort((a, b) => {
      const aValue = a[field]?.toString().toLowerCase();
      const bValue = b[field]?.toString().toLowerCase();
      if (aValue < bValue) return isAsc ? -1 : 1;
      if (aValue > bValue) return isAsc ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (row) => {
    navigate(`/adminsearch/edit/${row.SystemGeneratedFileName}`);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/api/searchFilesFilter`, {
        fileId,
        systemGeneratedFileName,
        originalFileName,
        filePath,
        fileType,
        startYear: parseInt(startYear) || 0,
        endYear: parseInt(endYear) || 0,
        author,
        title,
        category,
        notes,
        userId,
        message,
        startPages: parseInt(startPages) || 0,
        endPages: parseInt(endPages) || 0,
        textSize: parseFloat(textSize) || 0,
        pdfSize: parseFloat(pdfSize) || 0,
        virusStatus,
        currentStatus,
        nextStep,
        fromUploadTime,
        toUploadTime,
        reviewStatus
      });
      console.log(response)

      if (response.status === 200) {
        setResults(response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleReset = () => {
    setFileId('');
    setSystemGeneratedFileName('');
    setOriginalFileName('');
    setFilePath('');
    setFileType('');
    setStartYear('');
    setEndYear('');
    setAuthor('');
    setTitle('');
    setNotes('');
    setUserId('');
    setMessage('');
    setStartPages('');
    setEndPages('');
    setTextSize('');
    setPdfSize('');
    setVirusStatus('');
    setCurrentStatus('');
    setNextStep('');
    setFromUploadTime('');
    setToUploadTime('');
    setReviewStatus('');
    setResults([]);
  };

  return (
    <Container maxWidth="xl" style={{ margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        File Details Search
      </Typography>
      <form onSubmit={handleSearch}>
        <Grid container spacing={2} alignItems="center">
          {/* Render input fields for all search parameters */}
          <Grid item>
            <TextField label="File ID" variant="outlined" value={fileId} onChange={(e) => setFileId(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="System Generated Filename" variant="outlined" value={systemGeneratedFileName} onChange={(e) => setSystemGeneratedFileName(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Original Filename" variant="outlined" value={originalFileName} onChange={(e) => setOriginalFileName(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="File Path" variant="outlined" value={filePath} onChange={(e) => setFilePath(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="File Type" variant="outlined" value={fileType} onChange={(e) => setFileType(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Start Year" variant="outlined" value={startYear} onChange={(e) => setStartYear(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="End Year" variant="outlined" value={endYear} onChange={(e) => setEndYear(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Author" variant="outlined" value={author} onChange={(e) => setAuthor(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Category" variant="outlined" value={category} onChange={(e) => setCategory(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Notes" variant="outlined" value={notes} onChange={(e) => setNotes(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="User ID" variant="outlined" value={userId} onChange={(e) => setUserId(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Message" variant="outlined" value={message} onChange={(e) => setMessage(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Start Pages" variant="outlined" value={startPages} onChange={(e) => setStartPages(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="End Pages" variant="outlined" value={endPages} onChange={(e) => setEndPages(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Text Size" variant="outlined" value={textSize} onChange={(e) => setTextSize(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="PDF Size" variant="outlined" value={pdfSize} onChange={(e) => setPdfSize(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Virus Status" variant="outlined" value={virusStatus} onChange={(e) => setVirusStatus(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Current Status" variant="outlined" value={currentStatus} onChange={(e) => setCurrentStatus(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField label="Next Step" variant="outlined" value={nextStep} onChange={(e) => setNextStep(e.target.value)} style={{ minWidth: 120 }} />
          </Grid>
          <Grid item>
            <TextField
              label="From Upload Time"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={fromUploadTime}
              onChange={(e) => setFromUploadTime(e.target.value)}
              style={{ minWidth: 180 }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="to Upload Time"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={toUploadTime}
              onChange={(e) => setToUploadTime(e.target.value)}
              style={{ minWidth: 180 }}
            />
          </Grid>
          <Grid item>
          <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={reviewStatus}
                onChange={(e) => setReviewStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="processed">Processed</MenuItem>
                <MenuItem value="Admin Review">Unprocessed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button type="button" variant="outlined" color="secondary" onClick={handleReset}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>


      {results.length > 0 ? (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* Render table headers for all fields */}
                  <TableCell onClick={() => handleSort('fileId')}>File ID</TableCell>
                  <TableCell onClick={() => handleSort('systemGeneratedFileName')}>System Generated Filename</TableCell>
                  {/* Add more columns similarly */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.FileId}>
                    <TableCell>{row.FileId}</TableCell>
                    <TableCell>{row.SystemGeneratedFileName}</TableCell>
                    {/* Add more cells similarly */}
                    <TableCell>
                      <Button onClick={() => handleEdit(row)} startIcon={<Edit />}>Edit</Button>
                      <Button onClick={() => {}} startIcon={<Delete />} color="secondary">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Typography variant="h6" align='center' style={{ marginTop: '20px 0px' }}>
          No results found
        </Typography>
      )}
    </Container>
  );
};
