import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Button, Form, Modal } from 'react-bootstrap';
import { API_URL } from '../../../environment';
import "./editdocument.scss";

// Set up the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EditDocument = ({ initialPage = 1 }) => {
  const { pagenumber, systemgeneratedfilename, FileId } = useParams();
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [documentContent, setDocumentContent] = useState('');
  const [updatedContent, setUpdatedContent] = useState('');
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const [targetPage, setTargetPage] = useState(initialPage);
  const [isDirty, setIsDirty] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null); // Track pending navigation
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    // Fetch initial document content and PDF URL
    fetch(`${API_URL}/api/adminsearch/edit/view/${systemgeneratedfilename}/${pagenumber}/${FileId}`)
      .then(response => response.json())
      .then(data => {
        if (isMounted) {
          setDocumentContent(data.content);
          setUpdatedContent(data.content);
          setCurrentPage(Number(pagenumber.replace(/^0+/, '')));
          setPdfFileUrl(data.pdfUrl);
        }
      })
      .catch(error => console.error('Error fetching document:', error));

    return () => {
      isMounted = false;
    };
  }, [FileId, pagenumber, systemgeneratedfilename]);

  useEffect(() => {
    // Fetch content whenever the current page changes
    const fetchContentForCurrentPage = () => {
      console.log(systemgeneratedfilename, currentPage, FileId)
      fetch(`${API_URL}/api/adminsearch/edit/view/${systemgeneratedfilename}/${String(currentPage).padStart(5, '0')}/${FileId}`)
        .then(response => response.json())
        .then(data => {
          setUpdatedContent(data.content); // Update the content based on the current page
        })
        .catch(error => console.error('Error fetching document content:', error));
    };

    fetchContentForCurrentPage();
  }, [currentPage, systemgeneratedfilename, FileId]); // Fetch new content when currentPage changes

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleSave = () => {
    fetch(`${API_URL}/api/adminsearch/edit/edit/${systemgeneratedfilename}/${currentPage}/${FileId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        systemgeneratedfilename,
        pagenumber: currentPage, // Use currentPage here
        updatedContent,
        FileId
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setIsDirty(false);
          setTimeout(() => {
            navigate(`/adminsearch/edit/view/${systemgeneratedfilename}/${currentPage}/${FileId}`);
          }, 2000);
        } else {
          alert("An error occurred.");
        }
      })
      .catch(error => console.error('Error saving document:', error));
  };

  const goToTargetPage = () => {
    if (targetPage > 0 && targetPage <= numPages) {
      setCurrentPage(targetPage);
    }
  };

  const handleContentChange = (e) => {
    setUpdatedContent(e.target.value);
    setIsDirty(true);
  };

  const handleNavigation = (path) => {
    if (isDirty) {
      setPendingNavigation(path);
      setShowConfirmModal(true);
    } else {
      navigate(path);
    }
  };

  const handleConfirmLeave = () => {
    setShowConfirmModal(false);
    if (pendingNavigation) {
      navigate(pendingNavigation);
      setPendingNavigation(null);
    }
  };

  const handleBack = () => {
    handleNavigation(`/adminsearch/edit/view/${systemgeneratedfilename}/${pagenumber}/${FileId}`); // or any other path you want to navigate back to
  };

  return (
    <div className="edit-document-container">
      <div className="edit-content">
        <h2>Edit Page Number: {currentPage}</h2>
        <textarea
          value={updatedContent}
          onChange={handleContentChange}
          rows={10}
          style={{ width: "100%" }}
        />
        <Button variant="primary" onClick={handleSave}>Save</Button>
        <Button variant="secondary" onClick={handleBack} style={{ marginLeft: 10 }}>Back</Button>
      </div>

      {pdfFileUrl && (
        <div className="pdf-viewer-container">
          <Document
            file={pdfFileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf-document"
          >
            <Page pageNumber={currentPage} width={600} />
          </Document>
          <div className="pagination-controls">
            <Button
              variant="primary"
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(1)}
            >
              First
            </Button>
            <Button
              variant="primary"
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {numPages}
            </span>
            <Button
              variant="primary"
              disabled={currentPage >= numPages}
              onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, numPages))}
            >
              Next
            </Button>
            <Button
              variant="primary"
              disabled={currentPage >= numPages}
              onClick={() => setCurrentPage(numPages)}
            >
              Last
            </Button>
            <Form.Control
              type="number"
              min="1"
              max={numPages}
              value={targetPage}
              onChange={e => setTargetPage(Number(e.target.value))}
              style={{ width: '100px' }}
            />
            <Button onClick={goToTargetPage}>
              Go
            </Button>
          </div>
        </div>
      )}

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have unsaved changes. Are you sure you want to leave this page?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Stay
          </Button>
          <Button variant="primary" onClick={handleConfirmLeave}>
            Leave
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditDocument;
